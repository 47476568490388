import React from 'react';
import staticCube from '../assets/images/cube-static.gif';
import animatedCube from '../assets/images/cube.gif';

type Props = {
    animated: boolean,
}

function Cube({animated}: Props) {
  return (
    <>
        {animated ? <img src={animatedCube} className="App-logo" alt="logo" /> : <img src={staticCube} className="App-logo" alt="logo" />}
    </>
  );
}

export default Cube;
