import React, {useState} from 'react';
import WindowsWrapper from './components/WindowsWrapper';
import BlueScreen from './components/BlueScreen';
import './App.scss';

function App() {
  const [showBlueScreen, setShowBlueScreen] = useState(false);

  return (
    <>
      {showBlueScreen ? <BlueScreen /> : <WindowsWrapper setShowBlueScreen={setShowBlueScreen} />}
    </>
  );
}

export default App;
