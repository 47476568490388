import React from 'react';

function BlueScreen() {
  return (
    <div className='blue-screen'>
        <div className="overlay overlay--scanline" />
        <div className="overlay overlay--vignette" />
        <div className="flex-full-justify">
            <div className="flex-inner">
            <div className="text-center">
                <h1>blanks.io</h1>
            </div>
            <p>
                A fatal exception 0x1337 has occurred at ./app.js:1. The current website
                will be terminated.
            </p>
            <ul>
                <li>
                <p>
                    Please enable Javascript in your browser settings! This website will
                    not work without it!
                </p>
                </li>
            </ul>
            <p className="text-center">
                Press F5 to continue <span className="blink">_</span>
            </p>
            </div>
        </div>
    </div>
  );
}

export default BlueScreen;
