import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBNk50C9yaSdZonebsnly6HINqNqPTPsg0",
    authDomain: "blanks-5527d.firebaseapp.com",
    projectId: "blanks-5527d",
    storageBucket: "blanks-5527d.appspot.com",
    messagingSenderId: "618007681843",
    appId: "1:618007681843:web:dcfc37626eb898dc6cbc46",
    measurementId: "G-XNNZS2PNWC"
};



export const app = initializeApp(firebaseConfig);