import React, {useState} from 'react';

type Props = {
  inscription: number,
}

function InputController({inscription}: Props) {
  return (
    <div className='inscription'>
        <h2 className='inscription__title center'>Collector Card</h2>
        <div className='inscription__stats'>
            <div className='inscription__stats__image'>
                <img src="https://media.discordapp.net/attachments/1099337310753005578/1224700140061724682/Monkey_King.png?ex=661e71e3&is=660bfce3&hm=f1507b9fef792f3ca9ff0fd24daa48666900d2a031f6f84b9e30cdb695b7ff91&=&format=webp&quality=lossless" alt="" />
            </div>
            <div className='inscription__stats__data'>
                <p className='inscription-data'>Inscription {inscription}</p>
                <p className='inscription-data'>Inspiration Edward Scissorhands</p>
                <p className='inscription-data'>Unique trait origami hands</p>
                <p className='inscription-data'>Quote 'No one got hurt by taking profits'</p>
            </div>
        </div>
        <div className='inscription__lore'>
            <h3 className='inscription__lore__title center'>Lore:</h3>
            <p className='inscription__lore__text'>A cool motherfucker that likes to fuck bitches and flip burger</p>
        </div>
        <div className='inscription__assets'>
            <h3 className='inscription__lore__title center'>Other Assets:</h3>
            <div className='assets'>
                <img src="https://media.discordapp.net/attachments/1099337310753005578/1224700140061724682/Monkey_King.png?ex=661e71e3&is=660bfce3&hm=f1507b9fef792f3ca9ff0fd24daa48666900d2a031f6f84b9e30cdb695b7ff91&=&format=webp&quality=lossless" alt="" />
                <img src="https://media.discordapp.net/attachments/1099337310753005578/1224700140061724682/Monkey_King.png?ex=661e71e3&is=660bfce3&hm=f1507b9fef792f3ca9ff0fd24daa48666900d2a031f6f84b9e30cdb695b7ff91&=&format=webp&quality=lossless" alt="" />
            </div>
        </div>
    </div>
  )
}

export default InputController;
