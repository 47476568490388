import React from 'react';
import Typewriter from 'typewriter-effect';

type Props = {
  text: string,
  response?: string,
}

function Quest({text, response}: Props) {
  const modifiedText = response ? `${response} ${text}` : text;

  return (
    <div className='quest'>
        <Typewriter
            options={{
                cursor: '',
                delay: 50,
            }}
            onInit={(typewriter) => {
                typewriter.typeString(modifiedText)
                .start();
            }}
        />
    </div>
  )
}

export default Quest;
