import {steps, globalResponses, globalQuestResponses, Step} from '../config/quest';

export const getValidatedInput = (input: string, activeType: string) => {
    // if we're inside the quest
    if (activeType === 'quest') {
        if (['exit', 'quit'].includes(input)) { // check if user wants to exit quest
            return {
                text: ``,
                type: `home`,
            }
        } else if (globalQuestResponses.filter(response => response.keywords.includes(input)).length > 0) { // check if global quest response matches
            const text = globalQuestResponses.filter(response => response.keywords.includes(input))[0].response || '';

            return {
                text,
                type: `quest`,
            }
        } else {
            return {
                text: '',
                type: 'quest',
            }
        }
    }

    // check if first char is # in order to fetch inscription
    if (Array.from(input)[0] === '#') {
        const inscription = input.split('#')[1];

        // check if is number, later add check if inscription exists
        if (!isNaN(+inscription)) {
            return {
                text: ``,
                type: `inscription`,
            }
        } else {
            return {
                text: `I don't know which inscription ${input} is!`,
                type: `unknown-inscription`,
            }
        }
    } else if (input === 'quest') { // check if quest gets initialized
        return {
            text: 'init',
            currentQuest: 1,
            type: `quest`,
        }
    } else if (globalResponses.filter(response => response.keywords.includes(input)).length > 0) { // check if global response matches
        const text = globalResponses.filter(response => response.keywords.includes(input))[0].response || '';

        return {
            text,
            type: activeType,
        }
    } else {
        return {
            text: `I don't know what you are saying.`,
            type: `unknown`,
        }
    }
}

export const stepsParser = async (input: string, idToken: string) => {
    const res = await fetch('https://us-central1-blanks-5527d.cloudfunctions.net/submitQuest?' + new URLSearchParams({
      input,
      idToken,
    }), { mode: 'cors' });

    const stepRes = await res.json();

    return stepRes.step;
}