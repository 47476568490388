import React from 'react';
import Typewriter from 'typewriter-effect';

type Props = {
  text: string,
}

function Response({text}: Props) {
  return (
    <div className='quest'>
        <Typewriter
            options={{
                cursor: '',
                delay: 50,
            }}
            onInit={(typewriter) => {
                typewriter.typeString(text)
                .start();
            }}
        />
    </div>
  )
}

export default Response;