import React, {useState} from 'react';
import Typewriter from 'typewriter-effect';

type Props = {
  type: string,
  onSubmit: (input: string) => void,
}

function InputController({onSubmit, type}: Props) {
  const [text, setText] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit(text);
      setText('');
    }
  }

  return (
    <div className='input-controller center'>
      {type !== 'quest' && <Typewriter
        options={{
            cursor: '',
            delay: 50,
        }}
        onInit={(typewriter) => {
            typewriter.typeString('You are in a blank space')
            .start();
        }}
      />}
      <input className='input-controller__input' type="text" value={text} onKeyDown={handleKeyDown} onChange={handleChange} />
    </div>
  )
}

export default InputController;
