import React, {useState} from 'react';

function NYTimes() {
  return (
    <div className='nytimes'>
  <nyt_header version={1.0} type="homepage" /> <a name="top" />
  <map name="insidemap">
    {" "}
    <area
      shape="rect"
      coords="110,0,182,18"
      onclick="window.open('', 'homepopup', 'width=220,height=600,scrollbars=yes')"
      href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/features/moreinside.html"
      onmouseover="window.status='Click to See Inside Features';return true"
      target="homepopup"
    />
  </map>{" "}
  <map name="abuzzmap">
    <area
      shape="rect"
      coords="110,0,182,18"
      href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/features/moreabuzz.html"
      onmouseover="window.status='Click to See More Abuzz Features';return true"
      onclick="window.open('', 'homepopup', 'width=220,height=600,scrollbars=yes')"
      target="homepopup"
    />
  </map>
  <table border={0} cellPadding={0} cellSpacing={0} width={764}>
    {" "}
    <tbody>
      <tr>
        {" "}
        <td align="left" valign="middle" width={95} rowSpan={2}>
          <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/auth/login?URI=http://">
            <img
              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/promo_top.gif"
              width={94}
              height={64}
              border={0}
            />
          </a>
          <br />
        </td>{" "}
        <td width={1} bgcolor="#999999" rowSpan={2}>
          <br />
        </td>{" "}
        <td align="left" valign="bottom" width={480}>
          {" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/nytlogo480.gif"
            width={480}
            height={64}
            border={0}
          />{" "}
        </td>{" "}
        <td width={1} bgcolor="#999999" rowSpan={2}>
          <br />
        </td>{" "}
        <td width={6} rowSpan={2}>
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/spacer.gif"
            width={6}
            height={10}
            border={0}
          />
        </td>{" "}
        <td align="left" valign="top" width={182} rowSpan={2}>
          <a
            href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Right/sonaibo01/aibo_184x90s.gif/64316637323864633361326261393430"
            target="_top"
          >
            <img
              src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Right/sonaibo01/aibo_184x90s.gif/64316637323864633361326261393430"
              border={0}
              alt=""
            />
          </a>
        </td>{" "}
      </tr>{" "}
      <tr>
        {" "}
        <td align="center" valign="top" width={480}>
          {" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line1x568.gif"
            width={468}
            height={1}
            border={0}
            vspace={4}
          />
          <br />
          <center>
            <font size={-1} face="times new roman, times">
              <font size={-1}>MONDAY, DECEMBER 4, 1998 </font>
              9:14 AM ET |{" "}
              <font size={-1}>
                <a href="https://web.archive.org/web/20001204142500/http://weather.nytimes.com/partners/weather/weather.fcgi?saction=changePCity">
                  Personalize Your Weather
                </a>
              </font>
            </font>
          </center>{" "}
        </td>{" "}
      </tr>{" "}
      <tr>
        {" "}
        <td colSpan={6}>
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line3x764.gif"
            width={764}
            height={3}
            border={0}
            vspace={4}
          />
        </td>{" "}
      </tr>
    </tbody>
  </table>{" "}
  <table border={0} cellPadding={0} cellSpacing={0} width={764}>
    {" "}
    <tbody>
      <tr>
        {" "}
        <td align="left" valign="top" width={95}>
          <table border={0} cellPadding={0} cellSpacing={0} width={95}>
            {" "}
            <tbody>
              <tr>
                <form
                  method="POST"
                  action="https://web.archive.org/web/20001204142500/http://search.nytimes.com/guests/search/select_search.cgi"
                />{" "}
                <td align="left" valign="top" width={94}>
                  {" "}
                  <img
                    src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_search.gif"
                    alt="Search"
                    width={94}
                    height={18}
                    border={0}
                  />{" "}
                  <font className="text">
                    <input type="text" name="query" size={8} defaultValue="" />
                    <br />
                    <input
                      type="radio"
                      name="searchtype"
                      defaultChecked=""
                      defaultValue="today"
                    />
                    Latest News
                    <input
                      type="radio"
                      name="searchtype"
                      defaultValue="archive"
                    />
                    Archives <br />
                    <input type="submit" defaultValue="Search" />
                    <font size={-2}>
                      <a href="/web/20001204142500/http://nytimes.com/subscribe/help/searchtips.html">
                        Tips
                      </a>
                    </font>
                    <br />{" "}
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line2gray10x94.gif"
                      width={94}
                      height={10}
                      border={0}
                      vspace={2}
                    />
                    <br />
                  </font>{" "}
                </td>
                <td width={1} bgcolor="#999999">
                  <br />
                  <br />
                </td>{" "}
              </tr>{" "}
            </tbody>
          </table>{" "}
          <table border={0} cellPadding={0} cellSpacing={0} width={95}>
            {" "}
            <tbody>
              <tr>
                {" "}
                <td align="left" valign="top" width={94} bgcolor="#ffffff">
                  <font className="text">
                    {" "}
                    &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/yr/mo/day/jobmarket/">
                      Jobs
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/realestate">
                      Real Estate
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/marketplace/">
                      Shopping
                    </a>
                    <br />
                  </font>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td align="left" valign="top" width={94} bgcolor="#cecece">
                  <font className="text">
                    {" "}
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_news.gif"
                      alt="News"
                      width={94}
                      height={22}
                      border={0}
                    />{" "}
                    &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/business/index.html">
                      Business
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/opinion/index.html">
                      Editorial/Op-Ed
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/world/index.html">
                      International
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/national/index.html">
                      National
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/nyregion/index.html">
                      New York Region
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/yr/mo/day/front/">
                      NYT Front Page
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/national/text/index.html#obits">
                      Obituaries
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/politics/index.html">
                      Politics
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/yr/mo/day/late/">
                      Quick News
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/science/index.html">
                      Science/Health
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/sports/index.html">
                      Sports
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages-technology/index.html">
                      Tech/Internet
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/partners/weather/">
                      Weather
                    </a>{" "}
                    &nbsp;
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/linegray1x274.gif"
                      width={94}
                      height={1}
                      border={0}
                      vspace={4}
                    />
                    &nbsp;
                    <a href="/web/20001204142500/http://nytimes.com/2000/12/04/pageoneplus/corrections.html">
                      Corrections
                    </a>
                    <br />
                  </font>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td align="left" valign="top" width={94} bgcolor="#ffce63">
                  <font className="text">
                    {" "}
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_feat.gif"
                      alt="Features"
                      width={94}
                      height={22}
                      border={0}
                    />{" "}
                    &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/arts/index.html">
                      Arts
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/yr/mo/day/auto/">
                      Automobiles
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/books/home/">
                      Books
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/diversions/cartoons/">
                      Cartoons
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/diversions/">
                      Crossword
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://forums.nytimes.com/comment/">
                      Forums
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/diversions/">
                      Games
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/learning/">
                      Learning Network
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/living/index.html">
                      Living
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/magazine/home/">
                      Magazine
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/photos/">
                      Photos
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/travel/index.html">
                      Travel
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/weekinreview/index.html">
                      Week in Review
                    </a>{" "}
                  </font>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td align="left" valign="top" width={94} bgcolor="#ffffff">
                  {" "}
                  <img
                    src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line2gray10x94.gif"
                    width={94}
                    height={10}
                    border={0}
                  />
                  <br />
                  <nyt_ad>
                    <a
                      href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Bottom3/tesedi02/Navigator-86.gif/64316637323864633361326261393430"
                      target="_top"
                    >
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Bottom3/tesedi02/Navigator-86.gif/64316637323864633361326261393430"
                        border={0}
                        alt="Navigator"
                      />
                    </a>
                  </nyt_ad>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td align="left" valign="top" width={94} bgcolor="#ffffff">
                  <font className="text">
                    {" "}
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_serv.gif"
                      alt="Services"
                      width={94}
                      height={22}
                      border={0}
                    />{" "}
                    &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://archives.nytimes.com/archives/">
                      Archives
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/classified/">
                      Classifieds
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/subscribe/help/">
                      Help
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/adinfo/">
                      Media Kit
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/wireless">
                      NYT Mobile
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/nytstore/">
                      NYT Store
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/info/contents/siteindex.html">
                      Site Index
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/yr/mo/day/front/">
                      Text Version
                    </a>{" "}
                    &nbsp;
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/linegray1x274.gif"
                      width={94}
                      height={1}
                      border={0}
                      vspace={6}
                    />
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://homedelivery.nytimes.com/Subscribe">
                      Newspaper
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://homedelivery.nytimes.com/Subscribe">
                      -Subscribe at 50%
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://homedelivery.nytimes.com/">
                      -Customer Service
                    </a>{" "}
                    &nbsp;
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/linegray1x274.gif"
                      width={94}
                      height={1}
                      border={0}
                      vspace={6}
                    />
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/subscribe/help/jobs/">
                      Jobs at NYT Digital
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytco.com/">
                      The New York
                    </a>
                    <br /> &nbsp;
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytco.com/">
                      Times Co.
                    </a>{" "}
                  </font>{" "}
                  <img
                    src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line2gray10x94.gif"
                    width={94}
                    height={10}
                    border={0}
                  />{" "}
                </td>{" "}
                <td width={1} bgcolor="#999999">
                  <br />
                </td>{" "}
              </tr>{" "}
            </tbody>
          </table>
          <br />{" "}
          <table border={0} cellPadding={4} cellSpacing={0} width={95}>
            {" "}
            <tbody>
              <tr>
                {" "}
                <td align="left" valign="top" width={95} bgcolor="#cccccc">
                  {" "}
                  <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/marketplace">
                    <font
                      size={-1}
                      color="#990000"
                      face="times new roman, times"
                    >
                      <b>SHOPPING</b>
                    </font>
                  </a>{" "}
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Wine
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left1/hommkt06/hommkt06_10-09-2000.html/64316637323864633361326261393430?2000.12.04.14.25.13">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/evineyard/evineyard-yellow-86x40.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                    <br />3 wines for $21. $25 or more gets FREE delivery.
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Jewelry
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left2/hommkt11/Jewelry_11-28-00_Rings.html/64316637323864633361326261393430?urlid=3581&_RM_REDIR_=www.ashford.com/doorway.asp?__QUERY__">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/ashford/86x40_ashford.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                  </a>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left2/hommkt11/Jewelry_11-28-00_Rings.html/64316637323864633361326261393430?urlid=4301&_RM_REDIR_=www.ashford.com/doorway.asp?__QUERY__">
                    Stunning platinum &amp; diamond rings -free shipping!
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Luxury Gifts
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left3/hommkt12/Luxury_11-28-00_Montblanc.html/64316637323864633361326261393430?urlid=3581&_RM_REDIR_=www.ashford.com/doorway.asp?__QUERY__">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/ashford/86x40_ashford.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                  </a>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left3/hommkt12/Luxury_11-28-00_Montblanc.html/64316637323864633361326261393430?urlid=4304&_RM_REDIR_=www.ashford.com/doorway.asp?__QUERY__">
                    Montblanc fine writing instruments -free shipping!
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  {/*THIRD_PARTY_AD*/}
                  {/*hommkt13*/}
                  <font size={-1} color={990000} face="times new roman, times">
                    Jewelry
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left4/hommkt13/blunile-86.html/64316637323864633361326261393430?2000.12.04.14.25.13">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://view.avenuea.com/view/nyt_blnibnl00301aa_homepageshoppingfixed/direct/012000.12.04.14.25.13"
                      width={86}
                      height={40}
                      border={0}
                    />
                    <br />
                    Blue Nile. Diamonds and Fine Jewelry.
                  </a>
                  <br />
                  <br />
                  {/*END_AD*/}
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Books
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left5/hommkt01/00-11-01_Save90.html/64316637323864633361326261393430?bfmid=2181&sourceid=9664448&categoryid=home&_RM_REDIR_=service.bfast.com/bfast/click?__QUERY__">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/BarnesandNoble/bn-86x40.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                  </a>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left5/hommkt01/00-11-01_Save90.html/64316637323864633361326261393430?bfmid=2181&sourceid=9664734&categoryid=maghome&_RM_REDIR_=service.bfast.com/bfast/click?__QUERY__">
                    Save up to 90% on your favorite magazines
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Electronics
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left6/hommkt10/hpshopping_86x40.html/64316637323864633361326261393430?2000.12.04.14.25.13">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/JnR/hpshopping/hpshopping_86x40.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                    <br />J &amp; R Weekly Specials - Holiday Gift Ideas For
                    Less
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Travel
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left7/hommkt07/trip_86x40.html/64316637323864633361326261393430?2000.12.04.14.25.13">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/Trip/trip_86x40.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                    <br />
                    Book Travel at Trip.com
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  <font size={-1} color={990000} face="times new roman, times">
                    Photos
                  </font>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Left8/hommkt09/NYTimes_86x40.html/64316637323864633361326261393430?2000.12.04.14.25.13">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/ads/corbis/NYTimes_86x40w.gif"
                      width={86}
                      height={40}
                      border={0}
                    />
                    <br />
                    15% OFF Vintage New York Prints
                  </a>
                  <br />
                  <br />
                  <br />
                  <br />
                  {/* position not setup: Frame1 */}
                  <br />
                  <br />
                  {/* position not setup: Frame2 */}
                  <br />
                  <br />
                </td>{" "}
              </tr>{" "}
            </tbody>
          </table>
        </td>{" "}
        <td width={6}>
          <br />
        </td>{" "}
        <td align="left" valign="top" width={468}>
          {/*File not found Variables.promoPathhomepage/tempbanner.html*/}
          <table border={0} cellPadding={0} cellSpacing={0} width={468}>
            {" "}
            <tbody>
              <tr>
                {" "}
                <td align="left" valign="top" width={274}>
                  <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04COUR.html">
                    {" "}
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/2000/12/04/homepage/04COUR.2.gif"
                      width={274}
                      height={50}
                      border={0}
                    />
                  </a>
                  <font size={-1}>By DAVID FIRESTONE</font> <br />{" "}
                  <font size={-1}>FROM MONDAY'S TIMES</font>
                  <br />
                  Republican and Democratic lawyers completed their arguments in
                  Vice President Al Gore's contest of the Florida presidential
                  election. The judge's decision, which he promised by this
                  morning, could determine the next president.&nbsp;
                  <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04COUR.html">
                    <font size={-1}>Go to Article</font>
                  </a>
                  <br />{" "}
                  <font size={-1}>
                    •{" "}
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/2000/12/04/politics/04SCEN.html">
                      {" "}
                      For the Gore Team, a Moment of High Drama{" "}
                    </a>
                    <br />•{" "}
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/2000/12/04/politics/04CTEX.html">
                      TEXT: Excerpts From Final Arguments on the Recount{" "}
                    </a>
                    <br />•{" "}
                    <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/pages/politics/elections/index.html">
                      Complete Election Coverage
                    </a>
                  </font>{" "}
                  <p />
                  <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04GORE.html">
                    <font size={+1}>
                      <b>
                        Gore Reviews His Legal Options and Says He Remains
                        Determined to Press His Case
                      </b>
                    </font>
                  </a>
                  <br />
                  <font size={-1}>By KATHARINE Q. SEELYE</font> <br />{" "}
                  <font size={-1}>FROM MONDAY'S TIMES</font>
                  <br />
                  Vice President Al Gore remained determined to press on with
                  his legal case in Florida, holding a high-level strategy
                  session and taking his case to the public with an appearance
                  on CBS's "60 Minutes."
                  <br />{" "}
                  <font size={-1}>
                    •{" "}
                    <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04STRA.html">
                      Beyond Courtroom, Duels Continue on Other Fronts
                    </a>{" "}
                  </font>{" "}
                  <p />
                  <a href="/web/20001204142500/http://nytimes.com/2000/12/04/world/04RUSS.html">
                    <font size={+1}>
                      <b>
                        In Russia, the Ill and Infirm Include Health Care Itself
                      </b>
                    </font>
                  </a>
                  <br />
                  <font size={-1}>
                    By MICHAEL WINES with ABIGAIL ZUGER
                  </font>{" "}
                  <br /> <font size={-1}>FROM MONDAY'S TIMES</font>
                  <br />
                  With life expectancy falling in Russia, there is rising
                  concern that the country is struggling to preserve the
                  well-being of its people. Should it fail, experts say Russia
                  faces a grim future.
                  <br /> <p />
                  <a href="/web/20001204142500/http://nytimes.com/reuters/business/business-quaker-pepsi.html">
                    <font size={+1}>
                      <b>PepsiCo to Acquire Quaker Oats for $13.4 Billion</b>
                    </font>
                  </a>
                  <br />
                  <font size={-1}>By REUTERS, 7:53 A.M. ET</font>
                  <br />
                  PepsiCo said on Monday it will buy Quaker Oats in a $13.4
                  billion stock deal that gives the world's No. 2 beverage
                  company Quaker's Gatorade, the crown jewel of sports drinks,
                  and ends more than a month of speculation over who might
                  acquire Quaker.
                  <br /> <p />{" "}
                </td>{" "}
                <td width={6}>
                  <br />
                </td>{" "}
                <td width={1} bgcolor="#999999">
                  <br />
                </td>{" "}
                <td width={6}>
                  <br />
                </td>{" "}
                <td align="left" valign="top" width={182}>
                  <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04COUR.html">
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/2000/12/04/national/04cour.1.jpg"
                      border={0}
                    />
                  </a>{" "}
                  <div align="right">
                    <font size={-2}>(NYT, AP)</font>
                  </div>{" "}
                  <font size={-1}>
                    David Boies, left, attorney for Vice President Al Gore, and
                    Barry Richard, attorney for Gov. George W. Bush, continued
                    their legal tussling in a Tallahassee, Fla., courtroom on
                    Sunday.{" "}
                    <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04COUR.html">
                      Go to Article
                    </a>
                  </font>{" "}
                  <br /> <br />
                  <font size={-1} color="#990000" face="times">
                    <b>SPORTS</b>
                  </font>{" "}
                  <br />{" "}
                  <b>
                    <a href="/web/20001204142500/http://nytimes.com/2000/12/04/sports/04GIAN.html">
                      Giants Hold Off Redskins to Take N.F.C. East Lead
                    </a>
                  </b>
                  <font size={-1}> (9:13 a.m.)</font> <br />
                  <br />
                  <font size={-1} color="#990000" face="times">
                    <b>INTERNATIONAL</b>
                  </font>{" "}
                  <br />{" "}
                  <b>
                    <a href="/web/20001204142500/http://nytimes.com/2000/12/04/world/04SAUD.html">
                      Saudi Prince Urges Reform, and a Move From Shadow
                    </a>
                  </b>
                  <font size={-1}> (9:13 a.m.)</font> <br />
                  <br />
                  <font size={-1} color="#990000" face="times">
                    <b>NATIONAL</b>
                  </font>{" "}
                  <br />{" "}
                  <b>
                    <a href="/web/20001204142500/http://nytimes.com/2000/12/04/national/04BABY.html">
                      A Bit of Burping Is Allowed, if It Keeps Parents on the
                      Job
                    </a>
                  </b>
                  <font size={-1}> (9:13 a.m.)</font> <br />
                  <br /> <hr size={1} />
                  <table width={182} border={0} cellPadding={0} cellSpacing={0}>
                    <tbody>
                      <tr>
                        <td width={182} colSpan={3}>
                          <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/holidays">
                            <img
                              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/2000/11/30/living/holidays_homepage.gif"
                              width={182}
                              heigh={44}
                              border={0}
                            />
                          </a>
                          <br />
                          <font size={-1}>
                            <img
                              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/2000/11/30/living/holidays_home.gif"
                              width={75}
                              height={75}
                              border={0}
                              align="right"
                              hspace={4}
                            />
                            <b>The Neediest Cases</b>
                            <br />
                            The Queens Intensive Supportive Apartment Program is
                            one of the seven local charities supported by The
                            New York Times Neediest Cases Fund. Also:
                            <br />
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td width={88} valign="top">
                          <font size={-1}>
                            •{" "}
                            <a href="/web/20001204142500/http://nytimes.com/library/national/index-give.html">
                              Giving
                            </a>
                            <br />•{" "}
                            <a href="/web/20001204142500/http://nytimes.com/library/national/regional/index-neediest.html">
                              Neediest Cases
                            </a>
                            <br />•{" "}
                            <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/books/00/12/03/home/contents.html">
                              Holiday Books 2000
                            </a>
                          </font>
                        </td>
                        <td width={88} valign="top">
                          <font size={-1}>
                            •{" "}
                            <a href="/web/20001204142500/http://nytimes.com/2000/11/16/technology/circuits/index.html">
                              Circuits Buying Guide
                            </a>
                            <br />•{" "}
                            <a href="/web/20001204142500/http://nytimes.com/library/film/holiday-films-index.html">
                              Holiday Films
                            </a>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <p>
                            <font size={-1}>
                              <a href="/web/20001204142500/http://nytimes.com/pages/holidays/">
                                More Holidays Coverage
                              </a>
                            </font>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr size={1} />
                  <br />
                  <font size={1} face="times new roman, times">
                    <a href="#more">MORE HEADLINES</a>
                  </font>
                  <br />{" "}
                </td>{" "}
              </tr>
            </tbody>
          </table>
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line2gray5x468.gif"
            width={468}
            height={5}
            border={0}
            vspace={4}
          />{" "}
          <a
            href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Top/nytnyt81/468x60_holidays.gif/64316637323864633361326261393430"
            target="_top"
          >
            <img
              src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Top/nytnyt81/468x60_holidays.gif/64316637323864633361326261393430"
              border={0}
              width={468}
              height={60}
              alt=""
            />
          </a>{" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line2gray5x468.gif"
            width={468}
            height={5}
            border={0}
            vspace={4}
          />{" "}
          <a name="more" />
          <table border={0} cellPadding={0} cellSpacing={0} width={468}>
            {" "}
            <tbody>
              <tr>
                {" "}
                <td align="left" valign="top" width={228}>
                  <a href="/web/20001204142500/http://nytimes.com/pages/business/index.html">
                    <font size={-1} color="#990000">
                      <b>BUSINESS</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/business/04PETE.html">
                        Variety Braces as Competitors Enter Hallowed Precincts
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/technology/04DEAL.html">
                        Vulcan to Put $100 Million Into Oxygen Cable-Web
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/business/04PLAC.html">
                        Market Place: The Mighty Fall With AT&amp;T;
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/technology/04NECO.html">
                        New Economy: Executives See Trouble Spreading
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/business/text/index.html">
                      MORE BUSINESS NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />
                  <a href="/web/20001204142500/http://nytimes.com/pages/national/index.html">
                    <font size={-1} color="#990000">
                      <b>NATIONAL</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/national/04NURS.html">
                        U.S. Toughens Enforcement of Nursing Home Standards
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/science/04SHUT.html">
                        Space Station Gets Its Wings as Crew Gets Gifts From
                        Home
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/national/04KENN.html">
                        Kennedy Center Honors Span Spectrum of Song and Dance;
                        and Don't Forget Clint
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/science/04FORE.html">
                        U.S. Halts Logging in the Sierra Nevada
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/national/text/index.html">
                      MORE NATIONAL NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />
                  <a href="/web/20001204142500/http://nytimes.com/pages/politics/index.html">
                    <font size={-1} color="#990000">
                      <b>POLITICS</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04CHEN.html">
                        History Will View Gore 'In a Better Light' if He Quits
                        Soon, Cheney Says
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04LIEB.html">
                        Armed With Confidence and Resolve, Lieberman Fights On
                        for Gore
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04LIBE.html">
                        The Left Vows to Be Ready for Battle in January
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/politics/04SCEN.html">
                        For the Gore Team, a Moment of High Drama
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/politics/text/index.html">
                      MORE POLITICS NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />
                  <a href="/web/20001204142500/http://nytimes.com/pages/sports/index.html">
                    <font size={-1} color="#990000">
                      <b>SPORTS</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/sports/04JETS.html">
                        Jets Arrive at Playoff Doorstep
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/sports/04RHOD.html">
                        Curtis Martin Teaches a Lesson
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/sports/04COLL.html">
                        Sooners and Seminoles in Title Game
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/sports/04NEAG.html">
                        Neagle Leans to Rockies Despite Mets' Efforts
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/sports/text/index.html">
                      MORE SPORTS NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />
                  <a href="/web/20001204142500/http://nytimes.com/pages/arts/index.html">
                    <font size={-1} color="#990000">
                      <b>ARTS</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/arts/04BROO.html">
                        Gwendolyn Brooks, Whose Poetry Told of Being Black in
                        America, Dies at 83
                      </a>
                      <font size={-1}>2:45 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/arts/04GOOD.html">
                        The Goodman Theater: Sleek Digs for a Grande Dame
                      </a>
                      <font size={-1}>7:23 p.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/arts/04THOR.html">
                        'The Last of the Thorntons': Wry Smiles Temper the
                        Anguish of Old Age
                      </a>
                      <font size={-1}>7:23 p.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/arts/04GISH.html">
                        Inventing Life Steals Time; Living Life Begs It Back
                      </a>
                      <font size={-1}>7:23 p.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/arts/dailyarts/index.html">
                      MORE ARTS NEWS
                    </a>{" "}
                  </font>{" "}
                  <br />
                  <br />
                </td>{" "}
                <td width={5}>
                  <br />
                </td>{" "}
                <td width={1} bgcolor="#999999">
                  <br />
                </td>{" "}
                <td width={6}>
                  <br />
                </td>{" "}
                <td align="left" valign="top" width={228}>
                  <a href="/web/20001204142500/http://nytimes.com/pages/world/index.html">
                    <font size={-1} color="#990000">
                      <b>INTERNATIONAL</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/world/04INDO.html">
                        Indonesia Cracks Down on Separatists in Irian Jaya
                      </a>
                      <font size={-1}>9:06 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/world/04COLO.html">
                        U.S. Weighs Expanding Aid Plan to Colombia's Neighbors
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/world/04MEXI.html">
                        Mexico's New Leader Swiftly Seeks Peace in Chiapas
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/world/04GERM.html">
                        Is Germany on the Road to Diversity? The Parties Clash
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/world/text/index.html">
                      MORE INTERNATIONAL NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />{" "}
                  <a href="/web/20001204142500/http://nytimes.com/pages/nyregion/index.html">
                    <font size={-1} color="#990000">
                      <b>N.Y. REGION</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/nyregion/04WITN.html">
                        News Analysis: When Justice Hinges on What Is Seen, and
                        Believed
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/nyregion/04LIND.html">
                        Like the Turnpike, Trash Deal Divides Linden Into Two
                        Cities
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/nyregion/04GAY.html">
                        Gays Find Warm Welcome in a New Jersey Suburb
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/nyregion/04DIAR.html">
                        Metropolitan Diary
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/nyregion/text/index.html">
                      MORE N.Y. REGION NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />{" "}
                  <a href="/web/20001204142500/http://nytimes.com/pages/science/index.html">
                    <font size={-1} color="#990000">
                      <b>SCIENCE</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/science/04SHUT.html">
                        Space Station Gets Its Wings as Crew Gets Gifts From
                        Home
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/science/04FORE.html">
                        U.S. Halts Logging in the Sierra Nevada
                      </a>
                      <font size={-1}>9:00 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/business/04CORN.html">
                        Negligence Suit Is Filed Over Altered Corn
                      </a>
                      <font size={-1}>9:06 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/reuters/science/science-fossils-find-.html">
                        Fossils of Man's Earliest Ancestor Found in Kenya
                      </a>
                      <font size={-1}>7:39 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/science/text/index.html">
                      MORE SCIENCE NEWS
                    </a>
                  </font>{" "}
                  <br />
                  <br />{" "}
                  <a href="/web/20001204142500/http://nytimes.com/pages-technology/index.html">
                    <font size={-1} color="#990000">
                      <b>TECHNOLOGY</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/technology/04DEAL.html">
                        Vulcan to Put $100 Million Into Oxygen Cable-Web
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/technology/04NET.html">
                        Dot-Com Liquidations Put Consumer Data in Limbo
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/technology/04ECOMMERCE.html">
                        Web Sites for the Fast-Connected Without Losing the
                        Dial-Up Tortoise
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/technology/04NECO.html">
                        New Economy: Executives See Trouble Spreading
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages-technology/text/index.html">
                      MORE TECHNOLOGY NEWS
                    </a>{" "}
                  </font>{" "}
                  <br />
                  <br />{" "}
                  <a href="/web/20001204142500/http://nytimes.com/pages/living/index.html">
                    <font size={-1} color="#990000">
                      <b>LIVING</b>
                    </font>
                  </a>{" "}
                  <br />
                  <nyt_index_item></nyt_index_item> <br />
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/04/nyregion/04DIAR.html">
                        Metropolitan Diary
                      </a>
                      <font size={-1}>9:01 a.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/03/living/03STRE.html">
                        Leaving Room to Grow
                      </a>
                      <font size={-1}>11:04 p.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/03/living/03SENI.html">
                        Over 60: Fashion's Lost Generation
                      </a>
                      <font size={-1}>11:15 p.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>
                  <nyt_index_item>
                    <nyt_headline>
                      {" "}
                      <a href="/web/20001204142500/http://nytimes.com/2000/12/01/living/01MAME.html">
                        Travels With an Aunt: New York for Children
                      </a>
                      <font size={-1}>11:04 p.m. ET</font>
                    </nyt_headline>{" "}
                    <br />
                    <br />
                  </nyt_index_item>{" "}
                  <font size={1}>
                    <a href="/web/20001204142500/http://nytimes.com/pages/living/dailylivingnews/index.html">
                      MORE LIVING NEWS
                    </a>{" "}
                  </font>{" "}
                  <br />
                  <br />
                  <a href="#top">
                    <font size={1}>BACK TO TOP</font>
                  </a>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td colSpan={5}>
                  <br />
                  <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/headlines">
                    <font size={1}>
                      <center>
                        ADD NYTIMES HEADLINES TO YOUR SITE
                        WWW.NYTIMES.COM/HEADLINES
                      </center>
                    </font>
                  </a>
                </td>{" "}
              </tr>{" "}
            </tbody>
          </table>
        </td>{" "}
        <td width={6}>
          <br />
        </td>{" "}
        <td width={1} bgcolor="#999999">
          <br />
        </td>{" "}
        <td width={6}>
          <br />
        </td>{" "}
        <td align="left" valign="top" width={182}>
          {" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_inside.gif"
            alt="INSIDE"
            border={0}
            vspace={2}
            useMap="#insidemap"
          />{" "}
          <table width={182} border={0} cellPadding={5} cellSpacing={0}>
            {" "}
            <tbody>
              <tr>
                <td width={182} align="left" bgcolor="#cccccc">
                  <font size={-1} color={990000} face="times">
                    <b>
                      <nyt_kicker>
                        MAGAZINE
                        <br />
                      </nyt_kicker>
                    </b>
                  </font>
                  <a
                    href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/magazine/home/20001203mag-index.html"
                    target="nytimesmain"
                  >
                    <img
                      src="https://web.archive.org/web/20001204142500im_/http://www.nytimes.com/library/magazine/home/20001203secret-index.gif"
                      width={75}
                      height={75}
                      align="right"
                      border={0}
                    />
                  </a>
                  <nyt_headline>
                    {" "}
                    <font size={3} face="times">
                      <b>
                        <a
                          href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/magazine/home/20001203mag-index.html"
                          target="nytimesmain"
                        >
                          The Secrets Issue
                        </a>
                        <br />
                      </b>
                    </font>
                  </nyt_headline>{" "}
                  <nyt_byline>
                    <font size={-1} />{" "}
                  </nyt_byline>
                  <nyt_summary>
                    {" "}
                    <font size={-1}>
                      In this week's magazine: forsenics, hidden bunkers, secret
                      submarines and the travails of John Deutch.
                    </font>
                  </nyt_summary>
                </td>
              </tr>{" "}
            </tbody>
          </table>
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_abuzz.gif"
            alt="INSIDE"
            border={0}
            vspace={4}
            useMap="#abuzzmap"
          />
          <br />
          <table width={182} border={0} cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td width={182} align="left" valign="top">
                  <table
                    border={0}
                    width={182}
                    valign="top"
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr>
                        <td align="left" width={112} bgcolor="CCCCCC">
                          <table
                            width={112}
                            align="left"
                            border={0}
                            cellPadding={3}
                            cellSpacing={0}
                          >
                            <tbody>
                              <tr>
                                <td align="left" width={98} valign="bottom">
                                  <nyt_headline>
                                    <a
                                      href="https://web.archive.org/web/20001204142500/http://nytimes.abuzz.com/interaction/s.133837/discussion/e/1.2629"
                                      target="nytimesmain"
                                    >
                                      <b>Pinochet: Criminal or Savior?</b>
                                    </a>
                                  </nyt_headline>
                                </td>
                                <td width={14} align="right" valign="top">
                                  <img
                                    src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/icondiscuss.gif"
                                    alt="Ask"
                                    border={0}
                                    align="right"
                                    hspace={0}
                                    vspace={0}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <font size={-1}>
                                    Should Chile's former dictator face trial?{" "}
                                    <a
                                      href="https://web.archive.org/web/20001204142500/http://nytimes.abuzz.com/interaction/s.133837/discussion/e/1.2630"
                                      target="nytimesmain"
                                    >
                                      Add your thoughts
                                    </a>{" "}
                                    in Abuzz.
                                  </font>
                                  <br />
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={1} bgcolor="#CCCCCC">
                          <br />
                        </td>
                        <td width={1} bgcolor="#000066">
                          <br />
                        </td>
                        <td width={1} bgcolor="#FFCC33">
                          <br />
                        </td>
                        <td
                          width={67}
                          bgcolor="#000066"
                          align="left"
                          valign="top"
                        >
                          <br />
                          <a
                            href="https://web.archive.org/web/20001204142500/http://nytimes.abuzz.com/interaction/s.133837/discussion/e/1.2631"
                            target="nytimesmain"
                          >
                            <img
                              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/discussy.gif"
                              alt="Discuss This Question With Other Readers"
                              align="left"
                              border={0}
                              hspace={0}
                            />
                          </a>
                          <br clear="all" />
                          <img
                            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/askg.gif"
                            alt="Ask Other Readers Your Question in Abuzz"
                            align="left"
                            border={0}
                            hspace={0}
                          />
                          <br clear="all" />
                          <img
                            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/answerg.gif"
                            alt="Answer"
                            align="left"
                            border={0}
                            hspace={0}
                          />
                          <br clear="all" />
                          <img
                            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/reviewg.gif"
                            alt="Review"
                            align="left"
                            border={0}
                            hspace={0}
                          />
                          <br clear="all" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_mark_2.gif"
            alt="Markets: At Close"
            border={0}
            vspace={4}
          />{" "}
          <nyt_market_glance>
            <table border={0} cellPadding={0} cellSpacing={0} width={182}>
              <tbody>
                <tr>
                  {" "}
                  <td align="left" valign="top" bgcolor="#eeeeee">
                    <font size={-1}>&nbsp;Dow</font>
                  </td>{" "}
                  <td align="right" valign="top" bgcolor="#eeeeee">
                    <font size={-1}>
                      <b>10373.54</b>
                    </font>
                  </td>{" "}
                  <td align="right" valign="top" bgcolor="#eeeeee">
                    <font size={-1} color="#ab0000">
                      <b>-40.95</b>
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/latedown.gif"
                        alt="down"
                        width={9}
                        height={6}
                      />
                    </font>
                  </td>
                </tr>
                <tr>
                  <td align="left" valign="top">
                    <font size={-1}>&nbsp;Nasdaq</font>
                  </td>
                  <td align="right" valign="top">
                    <font size={-1}>
                      <b>2645.29</b>
                    </font>
                  </td>
                  <td align="right" valign="top">
                    <font size={-1} color="#0000ab">
                      <b>47.36</b>
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/lateup.gif"
                        alt="up"
                        width={9}
                        height={6}
                      />
                    </font>
                  </td>{" "}
                </tr>
                <tr>
                  {" "}
                  <td align="left" valign="top" bgcolor="#eeeeee">
                    <font size={-1}>&nbsp;S&amp;P; 500</font>
                  </td>{" "}
                  <td align="right" valign="top" bgcolor="#eeeeee">
                    <font size={-1}>
                      <b>1315.23</b>
                    </font>
                  </td>{" "}
                  <td align="right" valign="top" bgcolor="#eeeeee">
                    <font size={-1} color="#0000ab">
                      <b>0.28</b>
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/lateup.gif"
                        alt="up"
                        width={9}
                        height={6}
                      />
                    </font>
                  </td>{" "}
                </tr>
                <tr>
                  <td align="left" valign="top">
                    <font size={-1}>&nbsp;Russell 2000</font>
                  </td>
                  <td align="right" valign="top">
                    <font size={-1}>
                      <b>456.84</b>
                    </font>
                  </td>
                  <td align="right" valign="top">
                    <font size={-1} color="#0000ab">
                      <b>10.90</b>
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/lateup.gif"
                        alt="up"
                        width={9}
                        height={6}
                      />
                    </font>
                  </td>{" "}
                </tr>
                <tr>
                  {" "}
                  <td align="left" valign="top" bgcolor="#eeeeee">
                    <font size={-1}>&nbsp;NYSE</font>
                  </td>{" "}
                  <td align="right" valign="top" bgcolor="#eeeeee">
                    <font size={-1}>
                      <b>631.49</b>
                    </font>
                  </td>{" "}
                  <td align="right" valign="top" bgcolor="#eeeeee">
                    <font size={-1} color="#0000ab">
                      <b>1.71</b>
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/lateup.gif"
                        alt="up"
                        width={9}
                        height={6}
                      />
                    </font>
                  </td>{" "}
                </tr>{" "}
              </tbody>
            </table>
          </nyt_market_glance>
          <form
            method="post"
            action="https://web.archive.org/web/20001204142500/http://business.nytimes.com/news_titles.asp?action=news_titles"
          >
            <table border={0} cellPadding={0} cellSpacing={0} width={182}>
              {" "}
              <tbody>
                <tr>
                  {" "}
                  <td align="left" valign="top">
                    <font className="text">
                      Stock
                      <br />
                      Quotes
                    </font>{" "}
                  </td>{" "}
                  <td align="center" valign="top">
                    <nobr>
                      <input type="hidden" name="mode" defaultValue="news" />
                      <input type="hidden" name="action" defaultValue="quote" />
                      <input
                        type="text"
                        size={4}
                        name="ticker"
                        defaultValue=""
                      />
                      <input type="submit" defaultValue="Go" />
                    </nobr>{" "}
                  </td>{" "}
                  <td align="right" valign="top">
                    <font size={1} face="times new roman, times">
                      <a href="https://web.archive.org/web/20001204142500/http://business.nytimes.com/search.asp">
                        Look Up
                        <br />
                        Symbol
                      </a>
                    </font>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td colSpan={3}>
                    <a
                      href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Right1/nytbta04/$100_bill_184X25.gif/64316637323864633361326261393430"
                      target="_top"
                    >
                      <img
                        src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Right1/nytbta04/$100_bill_184X25.gif/64316637323864633361326261393430"
                        border={0}
                        alt=""
                      />
                    </a>{" "}
                  </td>{" "}
                </tr>
              </tbody>
            </table>
          </form>{" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_wire.gif"
            alt="Wire Services"
            border={0}
            vspace={4}
          />
          <a name="ap" />
          <font size={-1}>
            <a href="/web/20001204142500/http://nytimes.com/reuters/business/business-tyson-ibp-dc.html">
              Tyson Offers to Buy IBP for $2.8 billion
            </a>
          </font>
          <font size={-1}>9:04 a.m. ET</font>
          <br />
          <br />
          <font size={-1}>
            <a href="/web/20001204142500/http://nytimes.com/reuters/business/business-bindleyweste.html">
              Cardinal to Buy Bindley Western
            </a>
          </font>
          <font size={-1}>9:00 a.m. ET</font>
          <br />
          <br />
          <font size={-1}>
            <a href="/web/20001204142500/http://nytimes.com/aponline/business/AP-Cargill-Agribrands.html">
              Cargill To Acquire Agribrands
            </a>
          </font>
          <font size={-1}>8:58 a.m. ET</font>
          <br />
          <br />
          <font size={-1}>
            <a href="/web/20001204142500/http://nytimes.com/reuters/business/business-food-agribra.html">
              Cargill, Agribrands in $541 Million Deal
            </a>
          </font>
          <font size={-1}>8:58 a.m. ET</font>
          <br />
          <br />
          <font size={-1}>
            <a href="/web/20001204142500/http://nytimes.com/aponline/world/AP-Koreas-Family-Reunions.html">
              No Date Yet for Next Koreas Reunion
            </a>
          </font>
          <font size={-1}>8:57 a.m. ET</font>
          <br />
          <br />
          <font size={-1}>
            <a href="/web/20001204142500/http://nytimes.com/aponline/politics/AP-Senate-Cantwell.html">
              New Sen. - Elect: Gore Should Fight
            </a>
          </font>
          <font size={-1}>8:51 a.m. ET</font>
          <br />
          <br />
          <a href="/web/20001204142500/http://nytimes.com/pages/reuters/index.html">
            <font size={1}>MORE NEWS FROM REUTERS</font>
          </a>{" "}
          <br />{" "}
          <a href="/web/20001204142500/http://nytimes.com/pages/aponline/index.html">
            <font size={1}>MORE NEWS FROM AP</font>
          </a>{" "}
          <br />
          <br />
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_nyto.gif"
            alt="New York Today"
            width={182}
            height={18}
            border={0}
            vspace={4}
          />{" "}
          <font face="times new roman, times" size={-1}>
            <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/scripts/editorial.dll?eetype=Article&eeid=3552918&render=y">
              Apartment Envy? A Real Estate Marriage Sours
            </a>
            <br />
            <br />
            <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/scripts/editorial.dll?eeid=2821144&eetype=Article&render=y">
              At the Opera
            </a>
            <p>
              <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/scripts/editorial.dll?eeid=3552291&eetype=Article&render=y">
                Holidays: Small Screen Celebrations
              </a>
            </p>
          </font>
          <p>
            <font face="times new roman, times" size={-1}>
              <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/scripts/editorial.dll?eetype=Article&eeid=1235192&render=y">
                Theater This Week
              </a>
              <br />
              <br />
              <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/scripts/staticpage.dll?categoryid=36&only=y&spage=AE/genListing/genListing_details.htm&id=249324">
                TKTS Discounts
              </a>
              <br />
              <br />
              <a href="https://web.archive.org/web/20001204142500/http://www.nytoday.com/scripts/editorial.dll?eetype=Article&eeid=3015391&render=y&ck=&ver=2.10">
                Best Events This Week
              </a>
              <br />
            </font>
            <br />
            <a href="https://web.archive.org/web/20001204142500/http://www.newyorktoday.com/">
              <font size={1} face="times new roman, times">
                GO TO NEWYORKTODAY.COM
              </font>
            </a>{" "}
            <img
              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/linegray1x274.gif"
              width={184}
              height={1}
              border={0}
              vspace={6}
            />
            <a
              href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Position1/default/empty.gif/64316637323864633361326261393430"
              target="_top"
            >
              <img
                src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Position1/default/empty.gif/64316637323864633361326261393430"
                border={0}
                width={2}
                height={2}
                alt=""
              />
            </a>
            <a
              href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Position2/nytnyt36/Technology-86.gif/64316637323864633361326261393430"
              target="_top"
            >
              <img
                src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Position2/nytnyt36/Technology-86.gif/64316637323864633361326261393430"
                border={0}
                alt="Technology Section"
              />
            </a>
            <br />
            <a
              href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Position3/nytnyt61/educationlife-86/64316637323864633361326261393430"
              target="_top"
            >
              <img
                src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Position3/nytnyt61/educationlife-86/64316637323864633361326261393430"
                border={0}
                alt=""
              />
            </a>
            <a
              href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Position4/tripco04/NYTimes-Air-(86x90).gif/64316637323864633361326261393430"
              target="_top"
            >
              <img
                src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Position4/tripco04/NYTimes-Air-(86x90).gif/64316637323864633361326261393430"
                border={0}
                alt=""
              />
            </a>
            <img
              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/linegray1x274.gif"
              width={184}
              height={1}
              border={0}
              vspace={6}
            />{" "}
            <img
              src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_otd.gif"
              alt="On This Day"
              width={182}
              height={18}
              border={0}
              vspace={4}
            />{" "}
          </p>
          <table width={182} border={0} cellPadding={5} cellSpacing={0}>
            <tbody>
              <tr>
                <td width={182} align="left" bgcolor="#cccccc">
                  <nyt_headline>
                    <a href="/web/20001204142500/http://nytimes.com/learning/general/onthisday/001204onthisday.html">
                      <font size={3} face="times">
                        <b>
                          Dec. 4, 1945
                          <br />
                        </b>
                      </font>
                    </a>
                  </nyt_headline>
                  <nyt_byline>
                    <font size={-1} />
                  </nyt_byline>
                  <nyt_summary>
                    <font size={-1}>
                      On Dec. 4, 1945, the Senate approved United States
                      participation in the United Nations. (
                      <a href="/web/20001204142500/http://nytimes.com/learning/general/onthisday/big/1204.html">
                        See this front page.
                      </a>
                      ) In 1892, Francisco Franco, the Spanish dictator who
                      overthrew the democratic republic and headed an
                      authoritarian regime in Spain for 36 years, was born.{" "}
                      <a href="/web/20001204142500/http://nytimes.com/learning/general/onthisday/bday/1204.html">
                        (Read about his life.)
                      </a>
                    </font>
                  </nyt_summary>
                </td>
              </tr>
            </tbody>
          </table>
          <br />{" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/header/homepage/cat_sl.gif"
            alt="A Second Look"
            width={182}
            height={18}
            border={0}
            vspace={4}
          />{" "}
          <table width={182} border={0} cellPadding={5} cellSpacing={0}>
            {" "}
            <tbody>
              <tr>
                <td width={182} align="left" bgcolor="#cccccc">
                  <nyt_headline>
                    <font size={3} face="times">
                      <b>
                        <a
                          href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/race/index.html"
                          target="nytimesmain"
                        >
                          How Race Is Lived in America
                        </a>
                        <br />
                      </b>
                    </font>
                  </nyt_headline>{" "}
                  <nyt_byline>
                    {" "}
                    <font size={-1} />{" "}
                  </nyt_byline>
                  <nyt_summary>
                    <font size={-1}>
                      {" "}
                      Race relations in schools, in sports arenas, in pop
                      culture, at worship, and in the workplace make up this
                      <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/race/index.html">
                        series of articles
                      </a>
                      .
                      <br />•{" "}
                      <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/race/magnolia/">
                        Harvesting History: A Multimedia Presentation
                      </a>
                      <br />•{" "}
                      <a href="https://web.archive.org/web/20001204142500/http://forums.nytimes.com/comment/index.html#race">
                        Discuss Your Experiences With Race
                      </a>
                    </font>
                    <p>
                      <font size={3} face="times">
                        <b>
                          <a
                            href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/index-give.html"
                            target="nytimesmain"
                          >
                            Giving
                          </a>
                          <br />
                        </b>
                      </font>
                      <nyt_byline>
                        {" "}
                        <font size={-1} />{" "}
                      </nyt_byline>
                      <nyt_summary>
                        <font size={-1}>
                          Articles on the powerful and sometimes disappointing
                          effect celebrities can have on charity, the greening
                          of urban spaces and profiles of successful grass roots
                          organizations. <br />
                          Also: <br />•{" "}
                          <a
                            href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/112000give-hafn.html"
                            target="nytimesmain"
                          >
                            As Dot-Com Windfalls Shrink, Gifts Don't
                          </a>
                          <br />•{" "}
                          <a
                            href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/112000give-link.html"
                            target="nytimesmain"
                          >
                            Giving on the Web: A Collection of Links
                          </a>
                        </font>
                      </nyt_summary>
                    </p>
                    <p>
                      <font size={3} face="times">
                        <b>
                          <a
                            href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/111200edlife-index-edu.html"
                            target="nytimesmain"
                          >
                            Education Life
                          </a>
                          <br />
                        </b>
                      </font>
                      <nyt_byline>
                        {" "}
                        <font size={-1} />{" "}
                      </nyt_byline>
                      <nyt_summary>
                        <font size={-1}>
                          Although boarding schools have relaxed the rules, they
                          keep raising the academic ante. Also in this quarterly
                          education supplement:
                          <br /> •{" "}
                          <a
                            href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/111200edlife-tech-edu.html"
                            target="nytimesmain"
                          >
                            College's Clever Uses of Technology
                          </a>
                          <br />•{" "}
                          <a
                            href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/library/national/111200edlife-ellin-edu.html"
                            target="nytimesmain"
                          >
                            Academic Tune-Ups for Top Executives
                          </a>
                        </font>
                      </nyt_summary>
                    </p>
                  </nyt_summary>
                </td>
              </tr>{" "}
            </tbody>
          </table>
          <p />
        </td>{" "}
      </tr>
    </tbody>
  </table>{" "}
  <table border={0} cellPadding={0} cellSpacing={0} width={764}>
    {" "}
    <tbody>
      <tr>
        {" "}
        <td align="left" valign="top" colSpan={4}>
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line2gray5x468.gif"
            width={764}
            height={5}
            border={0}
            vspace={4}
          />
        </td>{" "}
      </tr>
    </tbody>
  </table>{" "}
  <table border={0} cellPadding={0} cellSpacing={0}>
    {" "}
    <tbody>
      <tr>
        {" "}
        <td align="left" valign="top" width={94}>
          <nyt_ad>
            <a
              href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/BottomLeft/nytstr02/OnlineStore-86.gif/64316637323864633361326261393430"
              target="_top"
            >
              <img
                src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/BottomLeft/nytstr02/OnlineStore-86.gif/64316637323864633361326261393430"
                border={0}
                alt="New York Times Online Store"
              />
            </a>
          </nyt_ad>{" "}
        </td>{" "}
        <td width={1} bgcolor="#999999">
          <br />
        </td>{" "}
        <td width={6}>
          <br />
        </td>{" "}
        <td align="left" valign="top" width={468}>
          <nyt_ad>
            <a
              href="https://web.archive.org/web/20001204142500/http://ads.nytimes.com/RealMedia/ads/click_lx.ads/homepage.nytimes.com/indexnew.html/0/Bottom/nytnyt81/468x60_holidaysb.gif/64316637323864633361326261393430"
              target="_top"
            >
              <img
                src="https://web.archive.org/web/20001204142500im_/http://ads.nytimes.com/RealMedia/ads/adstream_lx.ads/homepage.nytimes.com/indexnew.html/0/Bottom/nytnyt81/468x60_holidaysb.gif/64316637323864633361326261393430"
                border={0}
                width={468}
                height={60}
                alt=""
              />
            </a>
          </nyt_ad>{" "}
        </td>{" "}
      </tr>
    </tbody>
  </table>{" "}
  <table border={0} cellPadding={0} cellSpacing={0} width={764}>
    {" "}
    <tbody>
      <tr>
        {" "}
        <td align="center" valign="top" colSpan={4}>
          {" "}
          <img
            src="https://web.archive.org/web/20001204142500im_/http://graphics.nytimes.com/images/misc/homepage/line3x764.gif"
            width={764}
            height={3}
            border={0}
            vspace={4}
          />{" "}
          <br />
          <font size={-1} face="times new roman, times">
            <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/subscribe/help/copyright.html">
              <b>Copyright 2000 The New York Times Company</b>
            </a>{" "}
            |{" "}
            <a href="https://web.archive.org/web/20001204142500/http://www.nytimes.com/subscribe/help/privacy.html">
              Privacy Information
            </a>
          </font>
          <br />{" "}
        </td>{" "}
      </tr>
    </tbody>
  </table>
  </div>
  )
}

export default NYTimes;
