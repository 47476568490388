import React, {useEffect, useRef} from 'react';
import Webamp from "webamp";

const config = {
    initialTracks: [
      {
        metaData: {
          artist: "Tiesto",
          title: "Drifting",
        },
        url: "drifting.mp3",
      },
    ],
  };

function Winamp() {
    const playerRef = useRef(null);

    useEffect(() => {
      if (!playerRef) {
        return;
      }

      const webamp = new Webamp(config);
      webamp.renderWhenReady(playerRef.current as any);

      return () => {
        webamp.dispose();
      };
    }, [playerRef]);

    // Check if Winamp is supported in this browser
    if (!Webamp.browserIsSupported()) {
      return <div>Not supported</div>;
    }

    return <div className='winamp'><div ref={playerRef} /></div>;
}

export default Winamp;