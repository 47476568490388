import React, {useState, useEffect, useRef} from 'react';
import fearAudio from '../assets/audio/mix.mp3';
import Cube from './Cube';
import Inscription from './Inscription';
import Quest from './Quest';
import Response from './Response';
import InputController from './InputController';
import {getValidatedInput, stepsParser} from '../util/input-parser';
import {steps} from '../config/quest';
import { Transition } from 'react-transition-group';
import { getAuth, signInAnonymously } from "firebase/auth";
import {app} from '../config/firebase'

type Props = {
    setShowBlueScreen: (showBlueScreen: boolean) => void,
}

function Blanks({setShowBlueScreen}: Props) {
  const [inventory, setInventory] = useState<string[]>([]);
  const [inputResult, setInputResult] = useState<{text: string, type: string, currentQuest?: number}>({text: '', type: 'home'});
  const [userInteracted, setUserInteracted] = useState(false);
  const [step, setStep] = useState(steps[0]);
  const [user, setUser] = useState<any>();
  const [audio] = useState(new Audio(fearAudio));
  const cubeRef = useRef(null);
  const imgRef = useRef(null);

  const duration = 500;

  const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
  }

  const transitionStyles: any = {
      entering: { opacity: 1, maxHeight: '100%' },
      entered:  { opacity: 1 },
      exiting:  { opacity: 0 },
      exited:  { opacity: 0, maxHeight: 0 },
  };

  const onSubmit = async (input: string) => {
    const validatedInput = getValidatedInput(input, inputResult.type);

    if (validatedInput.type === 'quest' && (validatedInput.text === '' || validatedInput.text === 'init')) {
      const stepResponse = await stepsParser(input, user.user.accessToken) as any;

      stepResponse.type = 'quest';
      typeof stepResponse === 'string' ? setInputResult({...inputResult, text: stepResponse}): setInputResult(stepResponse);
      setStep(stepResponse);
    } else if (input === 'exit' || input === 'quit') {
        setStep(steps[0]);
    } else if (input.toLowerCase() === 'toilet sucks') {
        setShowBlueScreen(true);
    } else {
      setInputResult(validatedInput);
    }
  }

  const signInAnon = async() => {
    const auth = getAuth(app);

    if (!auth.currentUser) {
      const user = await signInAnonymously(auth);
      const idToken = await user.user.getIdToken(true);
      setUser(user);
    } else {
      setUser(auth.currentUser);
    }
  }

  useEffect(() => {
    signInAnon();
  }, [])

  useEffect(() => {
    if (userInteracted) {
      audio.play();
    }
  }, [userInteracted]);

  console.log(inputResult)

  return (
    <div className="App" onClick={() => setUserInteracted(true)}>
        <div className="app-header">
            <div className="content-wrapper">
                <Transition nodeRef={cubeRef} in={!step.image} timeout={duration} exit={false}>
                    {(state: any) => (
                        <div style={{
                            ...defaultStyle,
                            ...transitionStyles[state]
                          }}>
                              <Cube animated={userInteracted}  />
                        </div>
                    )}
                </Transition>
                <Transition nodeRef={imgRef} in={step.image} timeout={duration} exit={false}>
                    {(state: any) => (
                        <div style={{
                            ...defaultStyle,
                            ...transitionStyles[state]
                        }}>
                            <img src={step.image} />
                        </div>
                    )}
                </Transition>
                {inputResult.text !== '' && inputResult.text !== 'init' && inputResult.type !== 'quest' && <Response text={inputResult.text} key={inputResult.text} />}
                {inputResult.type === 'quest' && <Quest text={step.text} response={step.response} key={`${step.response}${step.text}`} />}
                <InputController onSubmit={(input: string) => onSubmit(input)} type={inputResult.type} />
                {inputResult.type === 'inscription' && <Inscription inscription={12} />}
            </div>
        </div>
    </div>
  );
}

export default Blanks;
