export type Step = {
    id: number,
    text: string,
    textItemPicked?: string,
    image?: string,
    response?: string,
    directions: {
        north: number|string|{step: number, wordMap: string[], requirements: string[], noPermission: string},
        west: number|string|{step: number, wordMap: string[], requirements: string[], noPermission: string},
        south: number|string|{step: number, wordMap: string[], requirements: string[], noPermission: string},
        east: number|string|{step: number, wordMap: string[], requirements: string[], noPermission: string},
    },
    instructions?: {keywords: string[], message: string, inventory: string[]},
};

export const globalResponses = [
    {
        keywords: ['love you'],
        response: 'I love you too',
    }
];

export const globalQuestResponses = [
    {
        keywords: ['fuck you'],
        response: 'Go fuck yourself',
    }
];

export const steps: Step[] = [
    {
        id: 1,
        text: 'You are in front of a forest, to the east is a house',
        directions: {
            north: `You can't go north`,
            west: '',
            east: 2,
            south: '',
        },
    },
    {
        id: 2,
        text: 'You are in front of the house, the door is closed. There is a key on the floor.',
        textItemPicked: 'You are in front of the house, the door is closed.',
        image: 'https://www.treasure-hunt-ideas.co.uk/site/siteImages/full-hunt/door-bell-map.png',
        directions: {
            north: '',
            west: 1,
            east: {
                wordMap: ['open door', 'unlock door'],
                step: 3,
                requirements: ['key'],
                noPermission: 'The door is locked!',
            },
            south: '',
        },
        instructions: {
            keywords: ['grab key', 'get key', 'take key'],
            inventory: ['key'],
            message: 'You grabbed the key!',
        }
    },
    {
        id: 3,
        text: 'You opened the door and went inside the house.',
        directions: {
            north: '',
            west: 2,
            east: '',
            south: '',
        },
    }
];